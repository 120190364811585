<template>
  <!-- 询价单 -->
  <div v-loading="loading">
    <div class="type-select">
      <div class="item"
        :class="{ active: curType === 0 }" v-if="handleHasPerms('M09SM04-routine')"
        @click="handleSelect(0)">
        常规告警({{totalNum}})
      </div>
      <div class="item"
        :class="{ active: curType === 1 }" v-if="handleHasPerms('M09SM04-abnormal')"
        @click="handleSelect(1)">
        异常告警({{totalNum2}})
      </div>
    </div>
    <GeneralTable ref="GeneralTable"
      v-if="curType === 0 && handleHasPerms('M09SM04-routine')"
      :column="column"
      :option="option"
      :dataList="dataList"
      @search-change="handleSearch"
      @export-data="handleExport"
      :totalNum="totalNum"
      tableName="StatisticalReport">
      <template slot="vehicleType-s">
         <el-select class="row-selectAddress"
                  v-model="searchData.alternativeVehicleType"
                  filterable
                  placeholder="设备类型"
                  clearable
                  @change="selectMachineryProductType"
                  @clear="clearMachineryProductType">
                  <el-option v-for="item in machineryProductTypeList"
                    :key="item.dictType + item.dictKey"
                    :label="item.dictName"
                    :value="item.dictType + item.dictKey"></el-option>
                </el-select>
      </template>
      <!-- <template slot="vehicleClass-s">
                <el-select class="row-selectAddress"
                  v-model="searchData.vehicleClass"
                  filterable
                  placeholder="设备种类"
                  clearable>
                  <el-option v-for="item in machineryEquipmentTypeList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"></el-option>
                </el-select>
      </template> -->
      <template slot="operate-left">
         <div class="el-button el-button--primary el-button--small" @click="onHandleMultipleClick" v-if="handleHasPerms('M09SM04-handle')">
            批量处理
          </div>
         <div class="el-button el-button--primary el-button--small is-plain" @click="handleExport(2)" v-if="handleHasPerms('M09SM04-export')">
            导出表头和附件
          </div>
      </template>
      <template slot="alarmSrc"
        slot-scope="{ item }">
        {{ item.alarmSrc == 1 ? '设备报警' : '平台计算报警' }}
      </template>
      <template slot="levelDesc"
        slot-scope="{ item }">
        {{ item.levelDesc ? item.levelDesc : '--' }}
      </template>
      <template slot="handleState"
        slot-scope="{ item }">
        {{ item.handleState == 1 ? '已处理' : '待处理' }}
      </template>
      <template slot="handleType"
        slot-scope="{ item }">
        {{ handleTypeObj[item.handleType] || '--' }}
      </template>
      <template slot="operate"
        slot-scope="{ item }">
        <el-button type="text"
          @click="handleShowDetails(item)"
          v-if="handleHasPerms('M09SM04-details')">报警详情</el-button>
        <el-button type="text"
          @click="handleDownloadSttachment(item)"
          v-if="(item && item.imgAttachment && item.imgAttachment.length || item && item.videoAttachment && item.videoAttachment.length) && handleHasPerms('M09SM04-download')">下载附件</el-button>
        <el-button type="text"
          @click="onHandleClick(item)"
          v-if="(item.handleState != 1 && !item.isCommercialVehicles) && handleHasPerms('M09SM04-handle')">处理</el-button>
      </template>
    </GeneralTable>
    <GeneralTable ref="GeneralTable"
      v-if="curType === 1 && handleHasPerms('M09SM04-abnormal')"
      :column="column2"
      :option="option2"
      :dataList="dataList2"
      @search-change="handleSearch"
      @export-data="handleExport"
      tableName="StatisticalReport2"
      :totalNum="totalNum2">
      <template slot="vehicleType-s">
         <el-select class="row-selectAddress"
                  v-model="searchData.alternativeVehicleType"
                  filterable
                  placeholder="设备类型"
                  clearable
                  @change="selectMachineryProductType"
                  @clear="clearMachineryProductType">
                  <el-option v-for="item in machineryProductTypeList"
                    :key="item.dictType + item.dictKey"
                    :label="item.dictName"
                    :value="item.dictType + item.dictKey"></el-option>
                </el-select>
      </template>
      <!-- <template slot="vehicleClass-s">
                <el-select class="row-selectAddress"
                  v-model="searchData.vehicleClass"
                  filterable
                  placeholder="设备种类"
                  clearable>
                  <el-option v-for="item in machineryEquipmentTypeList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"></el-option>
                </el-select>
      </template> -->
      <template slot="operate-left">
         <div class="el-button el-button--primary el-button--small" @click="onHandleMultipleClick" v-if="handleHasPerms('M09SM04-handle')">
            批量处理
          </div>
         <div class="el-button el-button--primary el-button--small is-plain" @click="handleExport(2)" v-if="handleHasPerms('M09SM04-export')">
            导出表头和附件
          </div>
      </template>
      <template slot="alarmSrc"
        slot-scope="{ item }">
        {{ item.alarmSrc == 1 ? '设备报警' : '平台计算报警' }}
      </template>
      <template slot="levelDesc"
        slot-scope="{ item }">
        {{ item.levelDesc ? item.levelDesc : '--' }}
      </template>
      <template slot="handleState"
        slot-scope="{ item }">
        {{ item.handleState == 1 ? '已处理' : '待处理' }}
      </template>
      <template slot="handleType"
        slot-scope="{ item }">
        {{ handleTypeObj[item.handleType] || '--' }}
      </template>
      <template slot="operate"
        slot-scope="{ item }">
        <el-button type="text"
          @click="handleShowDetails(item)"
          v-if="handleHasPerms('M09SM04-details')">报警详情</el-button>
        <el-button type="text"
          @click="handleDownloadSttachment(item)"
          v-if="(item && item.imgAttachment && item.imgAttachment.length || item && item.videoAttachment && item.videoAttachment.length) && handleHasPerms('M09SM04-download')">下载附件</el-button>
        <el-button type="text"
          @click="onHandleClick(item)"
          v-if="(item.handleState != 1 && !item.isCommercialVehicles) && handleHasPerms('M09SM04-handle')">处理</el-button>
      </template>
    </GeneralTable>
    <details-dialog v-model="isImport" :details="detailsData" @onHandleClick="onHandleClick(detailsData)" @downloadClick="handleDownloadSttachment(detailsData)"
      @changeList="changeList" />
      
    <Dialog @on-cmdSend="onCmdSendChange"
      :visible="visibleDialog"
      :iProp="alarmiProp"
      @input="closeDialog" />
      <alarmDetailsDialog  v-model="isVisible"
      :editDetail="detailsData" />
  </div>
</template>
<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import { hasPerms, dataPermissions } from "@/utils/auth";
import DetailsDialog from "@/views/SecurityMonitoring/StatisticalReport/components/DetailsDialog.vue";
import alarmDetailsDialog from "@/views/RealTimePositioning/components/alarmDetailsDialog";
import {
  getDictList, //获取产品列表
  getDictType, //获取设备列表
} from "@/api/getManagementData.js";
import {
  enquiryList,
  delEnquiryList,
  changeToInsurance,
  insEnquiryArchiveFast,
  dictionaryBatch,
  generateQuotationSheet
} from "@/api/policy.js";
import { tblAlarmdefineList, selectDictData, safetyManagePage, safetyManageDam, exportExcel, exportExcelAndAm, getAttachment } from "@/api/getData.js";
import Dialog from './components/DealWithDialog.vue';
import dayjs from 'dayjs';
import { appConfig } from '@/config/appConfig';
  let pickerOptions = {
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              const end = new Date(dayjs(new Date()).format('YYYY-MM-DD') + " 23:59:59");
              const start = new Date(dayjs(new Date()).format('YYYY-MM-DD') + " 00:00:00");
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '昨天',
            onClick(picker) {
              const end = new Date(dayjs(new Date()).format('YYYY-MM-DD') + " 23:59:59");
              const start = new Date(dayjs(new Date()).format('YYYY-MM-DD') + " 00:00:00");
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '最近一周',
            onClick(picker) {
              const end = new Date(dayjs(new Date()).format('YYYY-MM-DD') + " 23:59:59");
              const start = new Date(dayjs(new Date()).format('YYYY-MM-DD') + " 00:00:00");
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, 
         //  {
         //    text: '最近30天',
         //    onClick(picker) {
         //      const end = new Date(dayjs().format('YYYY-MM-DD') + " 23:59:59");
         //      const start = new Date(dayjs().format('YYYY-MM-DD') + " 00:00:00");
         //      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
         //      picker.$emit('pick', [start, end]);
         //    }
         //  }, {
         //    text: '本月',
         //    onClick(picker) {
         //      const end = new Date(dayjs(new Date()).endOf('month').format('YYYY-MM-DD') + " 23:59:59");
         //      const start = new Date(dayjs(new Date()).startOf('month').format('YYYY-MM-DD') + " 00:00:00");
         //      picker.$emit('pick', [start, end]);
         //    }
         //  }, {
         //    text: '上月',
         //    onClick(picker) {
         //      const end = new Date(dayjs(dayjs().add(-1, 'month')).endOf('month').format('YYYY-MM-DD') + " 23:59:59");
         //      const start = new Date(dayjs(dayjs().add(-1, 'month')).startOf('month').format('YYYY-MM-DD') + " 00:00:00");
         //      picker.$emit('pick', [start, end]);
         //    }
         //  }
          ]
        }
export default {
  components: {
    GeneralTable,
    DetailsDialog,
    Dialog,
    alarmDetailsDialog
  },
  data() {
    return {
      isImport: false,
      isVisible: false,
      totalNum: 0,
      totalNum2: 0,
      dataList: [],
      dataList2: [],
      option: {
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        isExport: hasPerms('M09SM04-export'), // 导出
        pageSize: 10,
        searchList: [
          {
            label: "设备名称",
            prop: "plate",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "设备类型",
            prop: "vehicleType-s",
            formType: "range-input",
            filterable: true,
            selectList: [],
          },
          // {
          //   label: "设备种类",
          //   prop: "vehicleClass-s",
          //   formType: "range-input",
          //   filterable: true,
          //   selectList: [],
          // },
          {
            label: "所属车组",
            prop: "groupName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "终端类型",
            prop: "terminalTypeName",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "智慧版mini", text: "智慧版mini" },
              { value: "智慧版", text: "智慧版" },
              { value: "智慧版plus", text: "智慧版plus" },
              { value: "鹰眼版", text: "鹰眼版" },
              { value: "效能版", text: "效能版" },
            ],
          },
          {
            label: "处理人",
            prop: "handler",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "处理方式",
            prop: "handleType",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "", text: "全部" },
              {
          value: 8,
          text: '调度下发--TTS播报'
        },
        {
          value: 32,
          text: '短信通知'
        },
        {
          value: 512,
          text: '语音通知'
        },
        {
          value: 128,
          text: "测试"
        },
        {
          value: 256,
          text: "误报"
        },
        {
          value: 0,
          text: "忽略报警"
        }
            ],
          },
          {
            label: "告警名称",
            prop: "alarmTypeId",
            formType: "select",
            filterable: true,
            selectList: [],
          },
          {
            label: "告警等级",
            prop: "level",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "", text: "全部" },
              { value: "1", text: "低" },
              { value: "2", text: "中" },
              { value: "3", text: "高" },
            ],
          },
          {
            label: "告警来源",
            prop: "src",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "1", text: "设备报警" },
              { value: "2", text: "平台计算报警" },
            ],
          },
          {
            label: "报警时间",
            formType: "datetimerange",
            clearable: true,
            pickerOptions: pickerOptions,
            propValue: [dayjs().format('YYYY-MM-DD') + " 00:00:00", dayjs().format('YYYY-MM-DD') + " 23:59:59"],
            prop: ["alarmTimeBegin", "alarmTimeEnd"],
          },
          {
            label: "处理时间",
            formType: "date",
            clearable: true,
            prop: 'handleTime',
          },
          {
            label: "处理状态",
            prop: "handleState",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "", text: "全部" },
              { value: "0", text: "待处理" },
              { value: "1", text: "已处理" },
            ],
          },
        ],
      },
      option2: {
        isExport: hasPerms('M09SM04-export'), // 编辑 @edit-change
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        pageSize: 10,
        searchList: [
          {
            label: "设备名称",
            prop: "plate",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "设备类型",
            prop: "vehicleType-s",
            formType: "range-input",
            filterable: true,
            selectList: [],
          },
          // {
          //   label: "设备种类",
          //   prop: "vehicleClass-s",
          //   formType: "range-input",
          //   filterable: true,
          //   selectList: [],
          // },
          {
            label: "所属车组",
            prop: "groupName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "终端类型",
            prop: "terminalTypeName",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "智慧版mini", text: "智慧版mini" },
              { value: "智慧版", text: "智慧版" },
              { value: "智慧版plus", text: "智慧版plus" },
              { value: "鹰眼版", text: "鹰眼版" },
              { value: "效能版", text: "效能版" },
            ],
          },
          {
            label: "处理人",
            prop: "handler",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "处理结果",
            prop: "handleType",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "", text: "全部" },
              {
          value: 8,
          text: '调度下发--TTS播报'
        },
        {
          value: 32,
          text: '短信通知'
        },
        {
          value: 512,
          text: '语音通知'
        },
        {
          value: 128,
          text: "测试"
        },
        {
          value: 256,
          text: "误报"
        },
        {
          value: 0,
          text: "忽略报警"
        }
            ],
          },
          {
            label: "告警名称",
            prop: "alarmTypeId",
            formType: "select",
            filterable: true,
            selectList: [],
          },
          {
            label: "告警等级",
            prop: "level",
            formType: "select",
            filterable: true,
            selectList: [
            //   { value: "1", text: "低" },
              { value: "2", text: "中" },
              { value: "3", text: "高" },
            ],
          },
          {
            label: "告警来源",
            prop: "src",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "1", text: "设备报警" },
              { value: "2", text: "平台计算报警" },
            ],
          },
          {
            label: "报警时间",
            formType: "datetimerange",
            clearable: true,
            pickerOptions: pickerOptions,
            propValue: [dayjs().format('YYYY-MM-DD') + " 00:00:00", dayjs().format('YYYY-MM-DD') + " 23:59:59"],
            prop: ["alarmTimeBegin", "alarmTimeEnd"],
          },
          {
            label: "处理时间",
            formType: "date",
            clearable: true,
            prop: 'handleTime',
          },
          {
            label: "处理状态",
            prop: "handleState",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "", text: "全部" },
              { value: "0", text: "待处理" },
              { value: "1", text: "已处理" },
            ],
          },
        ],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "设备名称",
          prop: "plate",
          width: 280,
          isShow: true,
        },
        {
          label: "报警名称",
          prop: "alarmName",
          width: 220,
          isShow: true,
        },
        {
          label: "报警等级",
          prop: "levelDesc",
          width: 120,
          isSlot: true,
          isShow: true,
        },
        {
          label: "报警来源",
          prop: "alarmSrc",
          width: 220,
          isShow: true,
          isSlot: true,
        },
        {
          label: "告警位置",
          prop: "address",
          isShow: true,
          width: 200,
        },
        {
          label: "报警开始时间",
          prop: "beginTime",
          width: 200,
          isShow: true,
        },
        {
          label: "报警结束时间",
          prop: "endTime",
          width: 200,
          isShow: true,
        },
        {
          label: "报警持续时长",
          prop: "duration",
          width: 200,
          isShow: true,
        },
        {
          label: "处理状态",
          prop: "handleState",
          width: 265,
          isShow: true,
          isSlot: true,
        },
        {
          label: "处理时间",
          prop: "handleTime",
          isShow: true,
        },
        {
          label: "设备类型",
          prop: "vehicleTypeName",
          isShow: true,
        },
        // {
        //   label: "设备种类",
        //   prop: "vehicleClassName",
        //   isShow: true,
        // },
        {
          label: "所属车组",
          prop: "groupName",
          isShow: true,
        },
        {
          label: "终端类型",
          prop: "terminalTypeName",
          isShow: true,
        },
        {
          label: "处理结果",
          prop: "handleType",
          width: 200,
          isSlot: true,
          isShow: true,
        },
        {
          label: "处理人",
          width: 200,
          prop: "handler",
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 220,
          fixed: "right",
          isSlot: true,
        },
      ],
      column2: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "设备名称",
          prop: "plate",
          width: 280,
          isShow: true,
        },
        {
          label: "报警名称",
          prop: "alarmName",
          width: 220,
          isShow: true,
        },
        {
          label: "报警等级",
          prop: "levelDesc",
          width: 120,
          isSlot: true,
          isShow: true,
        },
        {
          label: "报警来源",
          prop: "alarmSrc",
          width: 220,
          isShow: true,
          isSlot: true,
        },
        {
          label: "告警位置",
          prop: "address",
          isShow: true,
          width: 200,
        },
        {
          label: "报警开始时间",
          prop: "beginTime",
          width: 200,
          isShow: true,
        },
        {
          label: "报警结束时间",
          prop: "endTime",
          width: 200,
          isShow: true,
        },
        {
          label: "报警持续时长",
          prop: "duration",
          width: 200,
          isShow: true,
        },
        {
          label: "处理状态",
          prop: "handleState",
          width: 265,
          isShow: true,
          isSlot: true,
        },
        {
          label: "处理时间",
          prop: "handleTime",
          isShow: true,
        },
        {
          label: "设备类型",
          prop: "vehicleTypeName",
          isShow: true,
        },
        // {
        //   label: "设备种类",
        //   prop: "vehicleClassName",
        //   isShow: true,
        // },
        {
          label: "所属车组",
          prop: "groupName",
          isShow: true,
        },
        {
          label: "终端类型",
          prop: "terminalTypeName",
          isShow: true,
        },
        {
          label: "处理结果",
          prop: "handleType",
          width: 200,
          isSlot: true,
          isShow: true,
        },
        {
          label: "处理人",
          width: 200,
          prop: "handler",
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 220,
          fixed: "right",
          isSlot: true,
        },
      ],
      curType: 0,
      dataList1: [],
      isTurnPolicy: false,
      turnPolicyId: "",
      isFast: false,
      oldData: {},
      visibleDialog: false, // 报警处理模态框 
      alarmiProp: {
        selects: [], // 多选报警
        alarmParmas: {}, // 单个报警数据
        type: 0, //报警类型 0:常规 1：adas报警
      },
      detailsData: {},
      searchData: {
        alternativeVehicleType: '',
         vehicleType: '',
         vehicleClass: ''
      },
      machineryProductTypeList: [],
      machineryEquipmentTypeList: [],
      handleTypeObj: {
         '8': '调度下发--TTS播报',
         '32': '短信通知',
         '512': '语音通知',
         '128': "测试",
         '256': "误报",
         '0': "忽略报警"
      },
      loading: false,
    };
  },
  created() {
   !hasPerms('M09SM04-routine') ? this.curType = 1 : ''
   this.$nextTick(()=>{
      this.initSearch();
   })
  },
  computed: {},
  methods: {
    // 获取设备品牌筛选字典
    initSearch() {
      tblAlarmdefineList().then((res) => {
        if (res && res.obj) {
          let ind = this.option2.searchList.findIndex(
            (val) => val.label === "告警名称"
          );
          let selectList = res.obj.map(
            (val) => {
              return {
                text: val.alarmname,
                value: val.alarmId,
                category: val.category
              };
            }
          );
          this.option2.searchList[ind].selectList = selectList.filter(val=> val.category == 1)
          this.option2.searchList[ind].selectList.unshift({
            text: "全部",
            value: "",
          });

          let ind2 = this.option.searchList.findIndex(
            (val) => val.label === "告警名称"
          );
          this.option.searchList[ind2].selectList = selectList.filter(val=> val.category == 0);
          this.option.searchList[ind2].selectList.unshift({
            text: "全部",
            value: "",
          });
        }
      });
      
      getDictList({ type: '1' }).then(res => {
        if (res.flag === 1) {
          this.machineryProductTypeList = res.rows;
        }
      }).catch(err => {
        console.log(err);
      }); 
      // selectDictData({type: 1}).then((res) => {
      //    if (res && res.obj) {
      //       const { DictData, DictType } = res.obj
      //       let ind = this.option2.searchList.findIndex(
      //          (val) => val.label === "设备种类"
      //       );
      //       let ind2 = this.option2.searchList.findIndex(
      //          (val) => val.label === "设备类型"
      //       );
      //       let ind3 = this.option.searchList.findIndex(
      //          (val) => val.label === "设备种类"
      //       );
      //       let ind4 = this.option.searchList.findIndex(
      //          (val) => val.label === "设备类型"
      //       );
      //       this.option2.searchList[ind].selectList = DictData.map(
      //          (val) => {
      //          return {
      //             text: val.dictLabel,
      //             value: val.dictValue,
      //          };
      //          }
      //       );
      //       this.option2.searchList[ind].selectList.unshift({
      //          text: "全部",
      //          value: "",
      //       });
            
      //       this.option.searchList[ind3].selectList = DictData.map(
      //          (val) => {
      //          return {
      //             text: val.dictLabel,
      //             value: val.dictValue,
      //          };
      //          }
      //       );
      //       this.option.searchList[ind3].selectList.unshift({
      //          text: "全部",
      //          value: "",
      //       });
      //       this.option2.searchList[ind2].selectList = DictType.map(
      //          (val) => {
      //          return {
      //             text: val.dictName,
      //             value: val.dictType,
      //          };
      //          }
      //       );
      //       this.option2.searchList[ind2].selectList.unshift({
      //          text: "全部",
      //          value: "",
      //       });
      //       this.option.searchList[ind4].selectList = DictType.map(
      //          (val) => {
      //          return {
      //             text: val.dictName,
      //             value: val.dictType,
      //          };
      //          }
      //       );
      //       this.option.searchList[ind4].selectList.unshift({
      //          text: "全部",
      //          value: "",
      //       });
      //    }
      // })
    },
    handleDel(data) {
      if (data && data.length) {
        this.$confirm("删除后数据不能恢复是否确认要删除？", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let temp = {
              ids: [],
            };

            data.forEach((element) => {
              temp.ids.push(element.id);
            });
            temp.ids = temp.ids.join(",");
            delEnquiryList(temp.ids).then((res) => {
              if (res) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                });
                this.$refs.GeneralTable.getList();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message.error("请选择需要删除的数据！");
      }
    },
    handleEdit(type, enquiryNo, id) {
      if (type == "add") {
        this.$router.push({ name: "enquiryAdd" });
      } else if (type == "details") {
        this.$router.push({
          path: "/routerPolicyManagement/enquiryDetails/" + id,
        });
      } else {
        this.$router.push({
          path:
            "/routerPolicyManagement/enquiryEdit/" + type + "/" + this.curType,
        });
      }
    },
    handleSearch(data, pageNum, pageSize, searcType, isReset) {
      data.category = this.curType;
      data.current = data.pageNum;
      data.pageNumber = data.pageNum;
      data.size = data.pageSize;
      data = {...data, ...this.searchData}
      if (data.handleTime) {
        data.handleTimeBegin = dayjs(data.handleTime).format('YYYY-MM-DD') + " 00:00:00";
        data.handleTimeEnd = dayjs(data.handleTime).format('YYYY-MM-DD') + " 23:59:59";
      }
      if (isReset == "reset") {
         this.searchData.vehicleClass = '';
         this.searchData.vehicleType = '';
         this.searchData.alternativeVehicleType = '';
      //   if (this.isFast) {
      //     this.getList2(this.oldData);
      //   } else {
      //     this.getList(this.oldData);
      //   }
      } 
      // else
      // if (isReset == "fuzzy") {
      //   this.isFast = true;
      //   this.oldData = JSON.parse(JSON.stringify(data));
      //   this.getList2(data);
      // } else {
      // this.isFast = false;
      this.oldData = JSON.parse(JSON.stringify(data));
      this.getList(data);
      // }
    },
    getList(data) {
      this.loading = true;
      safetyManagePage(data).then((res) => {
        this.loading = false;
         this.totalNum = res.extend && res.extend.generalAlarmCount ? Number(res.extend.generalAlarmCount) : 0;
         this.totalNum2 = res.extend && res.extend.abnormalAlarmCount ? Number(res.extend.abnormalAlarmCount) : 0;
        if (this.curType === 0) {
          this.dataList = res.rows;
        } else {
          this.dataList2 = res.rows;
        }
      }).catch(err=>{
        this.loading = false;
         console.log(err);
        if (this.curType === 0) {
          this.totalNum = 0;
          this.dataList = [];
        } else {
          this.totalNum2 = 0;
          this.dataList2 = [];
        }
      });
    },
    getList2(data) {
      insEnquiryArchiveFast({
        current: data.pageNum,
        size: data.pageSize,
        // enquiryType: data.keyword,
        enquiryNo: data.keyword,
        policyHolder: data.keyword,
        // insured: data.keyword,
        insuranceTypeName: data.keyword,
        // insuranceCompany: data.keyword,
        salesmanName: data.keyword,
        frameNoLike: data.keyword,
        brandName: data.keyword,
        // equipmentType: data.keyword,
        recorderName: data.keyword,
        fastLike: data.keyword,
      }).then((res) => {
        if (this.curType === 0) {
          this.dataList = res.data.records;
          this.totalNum = res.data.total || 0;
        } else {
          this.dataList2 = res.data.records;
          this.totalNum2 = res.data.total || 0;
        }
      }).catch(err=> {
        if (this.curType === 0) {
          this.dataList = [];
          this.totalNum = 0;
        } else {
          this.dataList2 = [];
          this.totalNum2 = 0;
        }
      });
    },
    handleSelect(type) {
      this.curType = type;
      // this.searchData.vehicleClass = '';
      // this.searchData.vehicleType = '';
      // this.machineryEquipmentTypeList = [];
      this.$refs.GeneralTable.searchData.pageNum = 1;
      this.$refs.GeneralTable.tableSelectionList = []
      this.$refs.GeneralTable.getList();
    },
    handleShowDetails(item) {
      // let imgList = [];
      // let videoList = [];
      // if (item.attachment && item.attachment.length) {
      //    item.attachment.map(val=>{
      //       if (!/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(val)) {
      //          videoList.push(val);
      //       } else {
      //          imgList.push(val);
      //       }
      //    })
      // }
      // item.imgList = imgList;
      // item.videoList = videoList;
      if (item.isCommercialVehicles) {
        this.detailsData = { ...item }
        this.isVisible = true;
        return false;
      }
      getAttachment({
         alarmId: item.alarmId
      }).then(res=>{
         this.detailsData = { ...item, ...res.obj};
      })
      this.isImport = true;
    },
    handleDownloadSttachment(item) {
      // safetyManageDam({alarmId: item.alarmId})
      window.open(appConfig.base2GUrl2 + `/safetyManage/dam?alarmId=${item.alarmId}&token=${this.$store.state.appToken}&platform=PC`, '_blank')
    },
    changeList() {
      this.$refs.GeneralTable.getList();
    },
    handleExport(data) {
      if (data != 2) {
         let ids = [];
         if (data && data.length) {
            ids = data.map((val) => val.alarmId)
         }
         exportExcel({...this.oldData, alarmIds: ids}).then((res) => {
         if (res && res.obj) {
            window.open(appConfig.base2GUrl2 + res.obj + `&token=${this.$store.state.appToken}&platform=PC`, '_blank')
         }
         });
      }else {
         if (!this.$refs.GeneralTable.tableSelectionList || !this.$refs.GeneralTable.tableSelectionList.length) {
         this.$message.warning("请选择需要导出的数据！");
         return false;
         }
         let ids = this.$refs.GeneralTable.tableSelectionList.map((val) => val.alarmId);
         exportExcelAndAm(ids).then((res) => {
         if (res && res.obj) {
            window.open(appConfig.base2GUrl2 + res.obj + `&token=${this.$store.state.appToken}&platform=PC`, '_blank')
         }
         });
      }
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
    handleChangeToInsurance(id) {
      changeToInsurance({ id });
    },
    handleChangeToInsuranceDoc(id) {
      generateQuotationSheet({ id });
    },

    
    // 指令下发后刷新数据
    onCmdSendChange() {
      this.detailsData ? this.detailsData = {...this.detailsData, handleState: 1} : '';
      this.changeList();
    },
    // 模态框关闭
    closeDialog(model) {
      this.visibleDialog = model;
    },
    onHandleClick(row) {
      this.alarmiProp.selects = [];
      this.visibleDialog = true;
      this.alarmiProp.alarmParmas = {
        plate: row.plate, // 设备名称
        vehicleId: row.vehicleId, // 车辆Id
        groupName: row.groupName, // 车组名称
        groupId: row.groupId, // 车组Id
        alarmTime: row.timeBegin, // 报警时间
        alarmTypeEn: row.alarmTypeEn,
        alarmType: row.alarmType, // 报警类型
        alarmNameEnglish: row.alarmNameEnglish, // 报警类型
        alarmTypeCh: row.type,
        alarmAddress: row.address, // 报警地址
        alarmSourceStr: { 1: '车载终端', 2: '企业监控平台', 3: '政府监管平台', 9: '其他' }[row.alarmSource],
        alarmSource: row.alarmSource, // 报警来源
        alarmId: row.alarmId
      };
    },
    onHandleMultipleClick() {
      let selectionList = this.$refs.GeneralTable.tableSelectionList.filter(val=> val.handleState != 1 && !val.isCommercialVehicles);
      if (!selectionList || !selectionList.length) {
         this.$message.error('请选择未处理项！')
      }else if(selectionList && selectionList.length == 1) {
         this.onHandleClick(selectionList[0])
      }else {
         this.alarmiProp.selects = selectionList.map(val=>{
            return {
               plate: val.plate, // 设备名称
               vehicleId: val.vehicleId, // 车辆Id
               groupName: val.groupName, // 车组名称
               groupId: val.groupId, // 车组Id
               alarmTime: val.timeBegin, // 报警时间
               alarmTypeEn: val.alarmTypeEn,
               alarmType: val.alarmType, // 报警类型
               alarmNameEnglish: val.alarmNameEnglish, // 报警类型
               alarmTypeCh: val.type,
               alarmAddress: val.address, // 报警地址
               alarmSourceStr: { 1: '车载终端', 2: '企业监控平台', 3: '政府监管平台', 9: '其他' }[val.alarmSource],
               alarmSource: val.alarmSource, // 报警来源
               alarmId: val.alarmId
            }
         });
         this.visibleDialog = true;
         this.alarmiProp.alarmParmas = {}
      }
      // tableSelectionList
      // this.alarmiProp.selects = [];
      // this.visibleDialog = true;
      // this.alarmiProp.alarmParmas = {
      //   plate: row.plate, // 设备名称
      //   vehicleId: row.vehicleId, // 车辆Id
      //   groupName: row.groupName, // 车组名称
      //   groupId: row.groupId, // 车组Id
      //   alarmTime: row.timeBegin, // 报警时间
      //   alarmTypeEn: row.alarmTypeEn,
      //   alarmType: row.alarmType, // 报警类型
      //   alarmNameEnglish: row.alarmNameEnglish, // 报警类型
      //   alarmTypeCh: row.type,
      //   alarmAddress: row.address, // 报警地址
      //   alarmSourceStr: { 1: '车载终端', 2: '企业监控平台', 3: '政府监管平台', 9: '其他' }[row.alarmSource],
      //   alarmSource: row.alarmSource, // 报警来源
      //   alarmId: row.alarmId
      // };
    },
    
    // 选择产品类型
    selectMachineryProductType(e) {
      if (!e) {
        this.searchData.vehicleClass = '';
        this.searchData.vehicleType = '';
        return false;
      }
      const findData = this.machineryProductTypeList.find(val=> val.dictType + val.dictKey == e);
      this.searchData.vehicleClass = findData.dictKey;
      this.searchData.vehicleType = findData.dictType;
      // this.searchData.vehicleType ? this.getMachineryEquipmentTypeList() : '';
    },
    clearMachineryProductType() {
      this.searchData.vehicleClass = '';
      this.searchData.vehicleType = '';
      this.searchData.alternativeVehicleType = '';
      // this.machineryEquipmentTypeList = [];
    },
    // 获取设备类型列表
    getMachineryEquipmentTypeList() {
      getDictType({ dictType: this.searchData.vehicleType }).then(res => {
        if (res.flag === 1) {
          this.machineryEquipmentTypeList = res.obj;
        }
      }).catch(err => {
        console.log(err);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.type-select {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: -10px;
  border-radius: 10px 10px 0 0;
  background-color: #fff;
  .item {
    position: relative;
    padding: 13px 20px;
    color: #606266;
    margin-right: 20px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: #409eff;
    }
  }
  .active {
    color: #4278c9;
    font-weight: bold;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      border-radius: 4px;
      background-color: #4278c9;
    }
  }
}
</style>
